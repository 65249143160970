import React, { useEffect, useState } from 'react';
import { Routes, Route, useNavigate } from "react-router-dom";
import { BottomNavigation, BottomNavigationAction, AppBar, Toolbar, ButtonBase, Typography, MobileStepper, Button, Drawer, List, ListItem, ListItemAvatar, ListItemText, ListItemIcon, Divider } from "@mui/material";
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
// import MapIcon from '@mui/icons-material/Map';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
// import NotificationsIcon from '@mui/icons-material/Notifications';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import MenuIcon from '@mui/icons-material/Menu';
import LogoutIcon from '@mui/icons-material/Logout';
import CopyrightIcon from '@mui/icons-material/Copyright';
import Gravatar from 'react-gravatar';

import mainStyles from 'styles/mainStyles';
import RequireAuth from 'helpers/RequireAuth';
import Onboard from "routes/Onboard";
import OnboardChooseCustomer from 'routes/OnboardChooseCustomer';
import OnboardChooseDeviceType from 'routes/OnboardChooseDeviceType';
import OnboardChooseRegistry from 'routes/OnboardChooseRegistry';
import OnboardChooseName from 'routes/OnboardChooseName';
import OnboardChooseImages from 'routes/OnboardChooseImages';
import OnboardChooseBuilding from 'routes/OnboardChooseBuilding';
import OnboardAddBuilding from 'routes/OnboardAddBuilding';
import OnboardChoosePackage from 'routes/OnboardChoosePackage';
import OnboardChooseInstallation from 'routes/OnboardChooseInstallation';
import OnboardFinish from 'routes/OnboardFinish';
import ScanDevice from "routes/ScanDevice";
import Device from 'routes/Device';
import DeviceAdd from 'routes/DeviceAdd';
import DevicesList from 'routes/DevicesList';
import DeviceData from 'routes/DeviceData';
import DeviceDatafieldsList from 'routes/DeviceDatafieldsList';
import DeviceDatafieldEdit from 'routes/DeviceDatafieldEdit';
import DeviceAlarm from 'routes/DeviceAlarm';
import DeviceChooseInstallation from 'routes/DeviceChooseInstallation';
import DeviceChooseName from 'routes/DeviceChooseName';
import { ReactComponent as LogoIconWithout } from "assets/logo_pacco_without.svg";
import { ReactComponent as LogoIcon } from "assets/logo_pacco.svg";
import { ReactComponent as PoweredByIcon } from "assets/poweredby.svg";
import useGlobalState from 'hooks/useGlobalState';
import { getUser, logoutUser } from 'data/api';
import CircularLoader from './ui/CircularLoader';

const MainContainer = () => {
	const classes = mainStyles();
	const [state, dispatch] = useGlobalState();
	const navigate = useNavigate();

	const [loading, setLoading] = useState(true);
	const [drawerOpen, setDrawerOpen] = useState(false);
	const [value, setValue] = useState(0);

	const handleLogoClick = () => {
		navigate('/');
	}

	useEffect(() => {
		async function fetchUser() {
			const userData = await getUser();

			if (userData) {
				dispatch({ user: userData });

				setLoading(false);
			}
		}

		if (!state.user) {
			fetchUser();
		} else {
			setLoading(false);
		}
	}, [dispatch, state.user]);

	const handleBack = () => {
		switch (state.activeStep) {
			default:
			case 1:
				navigate('/onboard/customer');
				break;
			case 2:
				navigate('/onboard/devicetype');
				break;
			case 3:
				navigate('/onboard/registry');
				break;
			case 4:
				navigate('/onboard/name');
				break;
			case 5:
				navigate('/onboard/installation');
				break;
			case 6:
				navigate('/onboard/building');
				break;
			case 7:
				navigate('/onboard/images');
				break;
		}
	}

	const handleNext = () => {
		if (state.activeStep < 7) {
			switch (state.activeStep) {
				default:
				case 0:
					navigate('/onboard/devicetype');
					break;
				case 1:
					navigate('/onboard/registry');
					break;
				case 2:
					navigate('/onboard/name');
					break;
				case 3:
					navigate('/onboard/installation');
					break;
				case 4:
					navigate('/onboard/building');
					break;
				case 5:
					navigate('/onboard/images');
					break;
				case 6:
					navigate('/onboard/package');
					break;
			}
		} else {
			navigate('/onboard/finish');
		}
	}

	const toggleDrawer = () => {
		setDrawerOpen(!drawerOpen);
	}

	const handleLogout = async () => {
		const result = await logoutUser();

		if (result.status === 200) {
			navigate('/login');
		}
	}

	return (
		<>
			{!loading ?
				<>
					<div className={classes.appBarWrapper}>
						<AppBar position="static" elevation={0} className={classes.appBar}>
							<Toolbar>
								<ButtonBase
									focusRipple
									className={classes.logoContainer}
									focusVisibleClassName={classes.focusVisible}
									onClick={handleLogoClick}
								>
									<LogoIconWithout className={classes.logoWithout} />
								</ButtonBase>
								<Typography className={classes.appBarTitle}>
									{state.headerTitle}
								</Typography>
							</Toolbar>
						</AppBar>
					</div>

					<Routes>
						<Route path="/">
							<Route path="/:device/:deviceType/:token" element={<RequireAuth><Onboard /></RequireAuth>} />
							<Route path="/:device/:deviceType" element={<RequireAuth><Onboard /></RequireAuth>} />
							<Route path="/:device" element={<RequireAuth><Onboard /></RequireAuth>} />
							<Route path="" element={<RequireAuth><ScanDevice /></RequireAuth>} />
						</Route>
						<Route path="/onboard/customer" element={<RequireAuth><OnboardChooseCustomer /></RequireAuth>} />
						<Route path="/onboard/devicetype" element={<RequireAuth><OnboardChooseDeviceType /></RequireAuth>} />
						<Route path="/onboard/registry" element={<RequireAuth><OnboardChooseRegistry /></RequireAuth>} />
						<Route path="/onboard/name" element={<RequireAuth><OnboardChooseName /></RequireAuth>} />
						<Route path="/onboard/installation" element={<RequireAuth><OnboardChooseInstallation /></RequireAuth>} />
						<Route path="/onboard/building" element={<RequireAuth><OnboardChooseBuilding /></RequireAuth>} />
						<Route path="/onboard/building/add" element={<RequireAuth><OnboardAddBuilding /></RequireAuth>} />
						<Route path="/onboard/images" element={<RequireAuth><OnboardChooseImages /></RequireAuth>} />
						<Route path="/onboard/package" element={<RequireAuth><OnboardChoosePackage /></RequireAuth>} />
						<Route path="/onboard/finish" element={<RequireAuth><OnboardFinish /></RequireAuth>} />
						<Route path="/scan" element={<RequireAuth><ScanDevice /></RequireAuth>} />
						<Route path="/device">
							<Route path=":uuid" element={<RequireAuth><Device /></RequireAuth>} />
							<Route path="" element={<RequireAuth><Device /></RequireAuth>} />
						</Route>
						<Route path="/device/data/:datafield" element={<RequireAuth><DeviceData /></RequireAuth>} />
						<Route path="/device/datafields/list" element={<RequireAuth><DeviceDatafieldsList /></RequireAuth>} />
						<Route path="/device/datafield/edit/:datafield" element={<RequireAuth><DeviceDatafieldEdit /></RequireAuth>} />
						<Route path="/device/alarm/:datafield" element={<RequireAuth><DeviceAlarm /></RequireAuth>} />
						<Route path="/device/installation" element={<RequireAuth><DeviceChooseInstallation /></RequireAuth>} />
						<Route path="/device/name" element={<RequireAuth><DeviceChooseName /></RequireAuth>} />
						<Route path="/devices" element={<RequireAuth><DevicesList /></RequireAuth>} />
						<Route path="/device/add" element={<RequireAuth><DeviceAdd /></RequireAuth>} />
					</Routes>

					<Drawer
						anchor={'right'}
						open={drawerOpen}
						onClose={() => toggleDrawer()}
						classes={{ paper: classes.drawerPaper }}
					>
						<div style={{ width: '100%', textAlign: 'center', paddingTop: '20px' }}>
							<LogoIcon className={classes.logo} />
						</div>

						<List>
							<ListItem button key={10}>
								<ListItemAvatar>
									<Gravatar default='mp' email={state.user.email} className={classes.userimage} />
								</ListItemAvatar>
								<ListItemText primary={state.user.firstName + ' ' + state.user.lastName} />
							</ListItem>
							<ListItem onClick={() => handleLogout()}>
								<ListItemIcon>
									<LogoutIcon />
								</ListItemIcon>
								<ListItemText primary="Logout" />
							</ListItem>
							<Divider />
							<ListItem>
								<ListItemIcon>
									<CopyrightIcon />
								</ListItemIcon>
								<ListItemText primary="Om Pacco" />
							</ListItem>
						</List>

						<div style={{ position: 'absolute', right: 30, bottom: 20, height: 30 }}>
							<a href="https://senti.io/" target="_new"><PoweredByIcon /></a>
						</div>
					</Drawer>

					{state.showMobileStepper ? (
						<MobileStepper
							variant="progress"
							steps={8}
							position="static"
							activeStep={state.activeStep}
							sx={{ backgroundColor: '#898584' }}
							nextButton={
								<Button sx={{ color: '#fff' }} size="small" onClick={handleNext}>
									{state.activeStep === 7 ? 'Afslut' : 'Næste'}
									<KeyboardArrowRight />
								</Button>
							}
							backButton={
								<Button sx={{ color: '#fff' }} size="small" onClick={handleBack} disabled={state.activeStep === 0}>
									<KeyboardArrowLeft />
									Tilbage
								</Button>
							}
						/>
					) : (<></>)}

					{state.showBottomNavigation ? (
						<BottomNavigation
							showLabels
							value={value}
							onChange={(event, newValue) => {
								setValue(newValue);
							}}
							sx={{
								bgcolor: '#898584',

								'& .Mui-selected': {
									'& .MuiSvgIcon-root, & .MuiBottomNavigationAction-label': {
										color: '#fff',
									}
								}
							}}
						>
							<BottomNavigationAction label="Scan" icon={<QrCodeScannerIcon />} onClick={() => navigate('/scan')} />
							{/* <BottomNavigationAction label="" icon={<MapIcon />} /> */}
							<BottomNavigationAction label="Enheder" icon={<FormatListBulletedIcon />} onClick={() => navigate('/devices')} />
							{/* <BottomNavigationAction label="" icon={<NotificationsIcon />} /> */}
							<BottomNavigationAction label="Menu" icon={<MenuIcon />} onClick={() => toggleDrawer()} />
						</BottomNavigation>
					) : (<></>)}
				</>
				: <CircularLoader />}
		</>
	)
}

export default MainContainer;